<template>
    <page-title
        icon="bi-mortarboard-fill"
        title="学校・イベント編集"
    >
        <router-link :to="{name: 'SchoolList'}" class="btn btn-outline-primary">
            <i class="bi bi-reply"></i> 学校・イベント一覧へ
        </router-link>
    </page-title>

    <section class="section">
        <form>
            <div class="form-group col-md-6 mb-3">
                <label>タイプ</label>
                <select class="form-select" required v-model="shooting_type">
                    <option>学校</option>
                    <option>イベント</option>
                </select>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-3">
                    <label v-if="shooting_type === '学校'">学校名</label>
                    <label v-if="shooting_type === 'イベント'">イベント名</label>
                    <input type="text" class="form-control" value="北部小学校" required>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label>略称名</label>
                    <input type="text" class="form-control" value="北部小">
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label v-if="shooting_type === '学校'">学校担当者</label>
                <label v-if="shooting_type === 'イベント'">イベント担当者</label>
                <select class="form-select" required>
                    <option>-- 選択 --</option>
                    <option selected>片岡</option>
                    <option>江口</option>
                    <option>奥村</option>
                </select>
            </div>
            <div class="form-group mb-3">
                <div class="row">
                    <div class="col-4">
                        <label>都道府県</label>
                        <select class="form-select" required>
                            <option>-- 選択 --</option>
                            <template v-for="pref in prefs" :key="pref">
                                <option>{{ pref.label }}</option>
                                <template v-if="pref.label === '奈良県'">
                                    <option selected>{{ pref.label }}</option>
                                </template>
                            </template>
                        </select>
                    </div>
                    <div class="col-8">
                        <label>住所</label>
                        <input type="text" class="form-control" value="大和郡山市1-1-1">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>電話番号</label>
                <div class="input-group">
                    <input type="text" class="form-control" value="07">
                    <span class="input-group-text">-</span>
                    <input type="text" class="form-control" value="1111">
                    <span class="input-group-text">-</span>
                    <input type="text" class="form-control" value="2222">
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>先方窓口</label>
                <input type="text" class="form-control" value="田中太郎">
            </div>
            <div class="form-group col-md-10 mb-5">
                <label>注意事項</label>
                <textarea class="form-control" rows="5">出禁要確認</textarea>
            </div>
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-primary btn-lg">
                    <i class="bi bi-pencil-square"></i> 編集
                </button>
                <button type="button" class="btn btn-outline-danger btn-lg" @click="$refs.confirm_remove.show()">
                    <i class="bi bi-trash"></i> 削除...
                </button>
            </div>
        </form>
    </section>
    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import Pref from '@/models/enums/pref';

export default {
    name: 'SchoolEdit',
    components: {
        PageTitle,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            prefs: Pref.options(),
            shooting_type: '学校',
        }
    },
    mounted() {

    },
    methods: {
        remove() {
            this.$router.push({name: 'SchoolList'});
            this.showMessage('削除しました');
        }
    }
}
</script>

<style scoped>

</style>
